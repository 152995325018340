import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import '../styles/UsersPage.css'; // Import the CSS file

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosInstance.get('/v1/users');
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredUsers = users.filter(user => 
    (user.name ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (user.username ?? '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!users.length) {
    return <div>No users found.</div>;
  }

  return (
    <div className="users-page">
      <h2>Users</h2>
      <input 
        type="text" 
        placeholder="Search users..." 
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-input"
      />
      <ul>
        {filteredUsers.map(user => (
          <li key={user.GUUID} onClick={() => navigate(`/users/${user.GUUID}`, { state: { user } })}>
            {user.name ?? user.GUUID} ({user.username})
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UsersPage;
