import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';
import './ServicesPage.css';
import { TDS_auth } from '../../utils/auth';

const ServicesPage = ({ user }) => {
  const [services, setServices] = useState([]);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    // console.log(user);
    axios.get('https://home.thomasdye.net/api/services/all', {
      headers: {
        Authorization: `Bearer ${TDS_auth()}`,
      },
    })
      .then(response => {
        setServices(response.data);
        localStorage.setItem('services', JSON.stringify(response.data));
      })
      .catch(error => {
        console.error('Error fetching the services:', error);
      });

  }, []);

  return (
    <div className={`services-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Services</h2>
      {user && user.AccessRights.includes("net.thomasdye.web.home.Manageservices") && (
        <Link className="manage-services-button" to="/services/manage">
          Manage Services
        </Link>
      )}
      <div className="services-grid">
        {services.map(service => (
          <div className="service-box" key={service.name}>
            <img src={"https://home.thomasdye.net/TDSimages/" + service.logo} alt={service.name} className="service-logo" />
            <h3>{service.name}</h3>
            <p>{service.keywords}</p>
            <a href={service.url} target={service.target} rel="noopener noreferrer">
              Visit
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesPage;
