import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

const Header = ({ user }) => {
  const { isDarkMode, toggleDarkMode } = useTheme();

  const handleLogout = () => {
    window.location.replace('/logout');
  };

  return (
    <header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', backgroundColor: isDarkMode ? '#333' : '#f5f5f5', color: isDarkMode ? '#fff' : '#000' }}>
      {user && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={user.picture} alt={user.name} style={{ borderRadius: '50%', width: '40px', height: '40px', marginRight: '10px' }} />
          <span>{user.name}</span>
        </div>
      )}
      <div>
        <button onClick={toggleDarkMode} style={{ marginRight: '10px', background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
          <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} />
        </button>
        <button onClick={handleLogout} style={{ background: 'none', border: 'none', color: 'inherit', cursor: 'pointer' }}>
          Logout
        </button>
      </div>
    </header>
  );
};

export default Header;
