import React, { useEffect, useState } from 'react';
import axiosInstance from '../utils/axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/UserProfile.css'; // Import the CSS file

const UserProfile = () => {
    const location = useLocation();
    const user = location.state?.user;
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAccessRightsVisible, setIsAccessRightsVisible] = useState(false);
    const [isAPNSTokensVisible, setIsAPNSTokensVisible] = useState(false);
    const [isHomeAppSettingsVisible, setIsHomeAppSettingsVisible] = useState(false);
    const [isDevicesVisible, setIsDevicesVisible] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axiosInstance.get(`/v1/users/${user.GUUID}`);
                setUserInfo(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching user info:', error);
                setLoading(false);
            }
        };

        fetchUserInfo();
    }, [user]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userInfo) {
        return <div>Error loading user information.</div>;
    }

    return (
        <div className="user-profile">
              <button className="back-button" onClick={() => navigate('/users')}>Back to Users</button>
            <h2>{userInfo.name}</h2>
            <img src={userInfo.picture} alt={`${userInfo.name}`} className="profile-picture" />
            <p><strong>ID:</strong> {userInfo.GUUID}</p>
            <p><strong>Email:</strong> {userInfo.TDSemailRelayemail}</p>
            <p><strong>Account Type:</strong> {userInfo.accounttype}</p>
            <p><strong>Name:</strong> {userInfo.name}</p>
            <p><strong>Username:</strong> {userInfo.username}</p>
            <p><strong>Last Name:</strong> {userInfo.lastname}</p>
            <p><strong>Account Creation Date:</strong> {new Date(userInfo.AccountCreateDate).toLocaleString()}</p>
            <p><strong>Last Login:</strong> {new Date(userInfo.lastLogin * 1000).toLocaleString()}</p>

            <div className="collapsible-section">
                <h3 onClick={() => setIsAccessRightsVisible(!isAccessRightsVisible)}>
                    Access Rights {isAccessRightsVisible ? '-' : '+'}
                </h3>
                {isAccessRightsVisible && (
                    <ul>
                        {userInfo.AccessRights.map((right, index) => (
                            <li key={index}>{right}</li>
                        ))}
                    </ul>
                )}
            </div>

            <div className="collapsible-section">
                <h3 onClick={() => setIsAPNSTokensVisible(!isAPNSTokensVisible)}>
                    APNS Tokens {isAPNSTokensVisible ? '-' : '+'}
                </h3>
                {isAPNSTokensVisible && (
                    <>
                        {Object.entries(userInfo.APNS).map(([key, tokens]) => (
                            <div key={key}>
                                <h4>{key}</h4>
                                <ul>
                                    {tokens.map((token, index) => (
                                        <li key={index}>
                                            <strong>ENV:</strong> {token.ENV} - <strong>Token:</strong> {token.APNStoken}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </>
                )}
            </div>

            <div className="collapsible-section">
                <h3 onClick={() => setIsHomeAppSettingsVisible(!isHomeAppSettingsVisible)}>
                    Home App Settings {isHomeAppSettingsVisible ? '-' : '+'}
                </h3>
                {isHomeAppSettingsVisible && (
                    <div>
                        <ul>
                            {userInfo["net_thomasdye_TDS-Home"].HomeAppSettings.map((setting, index) => (
                                <li key={index}>{setting}</li>
                            ))}
                        </ul>

                        <div className="collapsible-section">
                            <h3 onClick={() => setIsDevicesVisible(!isDevicesVisible)}>
                                Devices {isDevicesVisible ? '-' : '+'}
                            </h3>
                            {isDevicesVisible && (
                                <>
                                    {userInfo["net_thomasdye_TDS-Home"].Dooraccess.Devices.map((device, index) => (
                                        <div key={device.id}>
                                            <p><strong>Model:</strong> {device.model}</p>
                                            <p><strong>ID:</strong> {device.id}</p>
                                            <p><strong>System Name:</strong> {device.systemName}</p>
                                            <p><strong>Name:</strong> {device.name}</p>
                                            <p><strong>System Version:</strong> {device.systemVersion}</p>
                                            <p><strong>Localized Model:</strong> {device.localizedModel}</p>
                                            <p><strong>Time:</strong> {new Date(device.time * 1000).toLocaleString()}</p>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export default UserProfile;
