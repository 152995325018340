import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import axios from 'axios';
import HomePage from './components/HomePage';
import Header from './components/Header';
import ServicesPage from './components/services/ServicesPage';
import ManageServicesPage from './components/services/ManageServicesPage';
import UserProfile from './components/UserProfile';
import UsersPage from './components/UsersPage';
import ServerStatusPage from './components/ServerStatusPage';
// import LoginPage from './LoginPage';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await axios.get('https://auth.thomasdye.net/auth/app/user/about/me', {
          headers: {
            'Authorization': `Bearer ${TDS_auth()}`,
          },
        });

        if (response.data.login && response.data.Error !== 'AUTH') {
          setIsAuthenticated(true);
          setUser(response.data);
        } else {
          window.location.href = 'https://auth.thomasdye.net/auth?redirect=https://home.thomasdye.net';
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        window.location.href = 'https://auth.thomasdye.net/auth?redirect=https://home.thomasdye.net';
      }
    };

    checkAuthentication();
  }, []);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider>
    <Router>
      <Header user={user} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services/manage" element={<ManageServicesPage user={user}  />} />
        <Route path="/services" element={<ServicesPage user={user} />} />
        <Route path="/users" element={<UsersPage />} />
         <Route path="/users/:GUUID" element={<UserProfile />} />
         <Route path="/server-status" element={<ServerStatusPage />} />
         
        {/* Add other routes here */}
      </Routes>
    </Router>
    </ThemeProvider>
  );
};

//  function to get the TDS_auth cookie

function TDS_auth() {
  var name = "TDS_Auth=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
        c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
    }
  }
  return "";
}


export default App;
