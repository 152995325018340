import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTheme } from '../../contexts/ThemeContext';
import './ManageServicesPage.css';
import { TDS_auth } from '../../utils/auth';

const ManageServicesPage = ({user}) => {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({ name: '', keywords: '', url: '', logo: '', target: '_blank' });
  const { isDarkMode } = useTheme();

  useEffect(() => {
    axios.get('https://home.thomasdye.net/api/services/all', {
      headers: {
        Authorization: `Bearer ${TDS_auth()}`,
      },
    })
      .then(response => {
        setServices(response.data);
        // localStorage.setItem('services', JSON.stringify(response.data));
      })
      .catch(error => {
        console.error('Error fetching the services:', error);
      });
  
}, []);

  const handleAddService = () => {
    // setServices([...services, newService]);
    // setNewService({ name: '', keywords: '', url: '', logo: '', target: '_blank' });
  };

  const handleReorderServices = (index, direction) => {
    // const newServices = [...services];
    // const [movedService] = newServices.splice(index, 1);
    // newServices.splice(index + direction, 0, movedService);
    // setServices(newServices);
    // localStorage.setItem('services', JSON.stringify(newServices));
  };
//   {user && user.AccessRights.includes("net.thomasdye.web.home.Manageservices") && (
    if (!user || !user.AccessRights.includes("net.thomasdye.web.home.Manageservices")) {

    return (
      <div className={`manage-services-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <h2>Manage Services</h2>
        <p>You do not have permission to manage services.</p>
      </div>
    );
    }
  return (
    <div className={`manage-services-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Manage Services</h2>
      <div className="new-service-form">
        <input type="text" placeholder="Name" value={newService.name} onChange={(e) => setNewService({ ...newService, name: e.target.value })} />
        <input type="text" placeholder="Keywords" value={newService.keywords} onChange={(e) => setNewService({ ...newService, keywords: e.target.value })} />
        <input type="text" placeholder="URL" value={newService.url} onChange={(e) => setNewService({ ...newService, url: e.target.value })} />
        <input type="text" placeholder="Logo URL" value={newService.logo} onChange={(e) => setNewService({ ...newService, logo: e.target.value })} />
        <select value={newService.target} onChange={(e) => setNewService({ ...newService, target: e.target.value })}>
          <option value="_blank">_blank</option>
          <option value="_self">_self</option>
          <option value="_parent">_parent</option>
          <option value="_top">_top</option>
        </select>
        <button onClick={handleAddService}>Add Service</button>
      </div>
      <div className="services-list">
        {services.map((service, index) => (
          <div className="service-box" key={service.name}>
            <img src={"https://home.thomasdye.net/TDSimages/" + service.logo} alt={service.name} className="service-logo" />
            <h3>{service.name}</h3>
            <p>{service.keywords}</p>
            <a href={service.url} target={service.target} rel="noopener noreferrer">
              Visit
            </a>
            <div className="reorder-buttons">
              {index > 0 && <button onClick={() => handleReorderServices(index, -1)}>Up</button>}
              {index < services.length - 1 && <button onClick={() => handleReorderServices(index, 1)}>Down</button>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManageServicesPage;
