import axios from 'axios';
import { TDS_auth } from './auth';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://auth.thomasdye.net/auth/UserManagmentAPI', // Set your base URL here
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Get the JWT token
    const token = TDS_auth();
    if (token) {
      // Add the token to the headers
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
