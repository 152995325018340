import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTheme } from '../contexts/ThemeContext';
import '../styles/ServerStatusPage.css';
import { TDS_auth } from '../utils/auth';

const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes

const ServerStatusPage = () => {
  const [statusData, setStatusData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const cachedData = localStorage.getItem('statusData');
    const cacheTimestamp = localStorage.getItem('statusDataTimestamp');
    const isCacheValid = cacheTimestamp && (new Date().getTime() - cacheTimestamp < CACHE_DURATION);

    if (cachedData && isCacheValid) {
      setStatusData(JSON.parse(cachedData));
      setLoading(false);
    } else {
      axios.get('https://status.server.thomasdye.net/app/serverissue/V10/service/all/Grouping', {
        headers: {
          Authorization: `Bearer ${TDS_auth()}`,
        },
      })
        .then((response) => {
          setStatusData(response.data.data);
          localStorage.setItem('statusData', JSON.stringify(response.data.data));
          localStorage.setItem('statusDataTimestamp', new Date().getTime());
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching the server status data:', error);
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!statusData.length) {
    return <div>No status data available.</div>;
  }

  return (
    <div className={`status-page ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Server Status</h2>
      <div className="status-grid">
        {statusData.map((service) => (
          <div
            className={`status-box ${service.working ? '' : 'not-working'}`}
            key={service.ID}
          >
            <img src={service.imageurl} alt={service.service} className="status-logo" />
            <h3>{service.service}</h3>
            <p>
              <strong>Status:</strong> {service.working ? 'Working' : 'Not Working'}
            </p>
            <p>
              <strong>Message:</strong> {service.mess}
            </p>
            {service.webaccess && (
              <p>
                <a href={service.weblink} target="_blank" rel="noopener noreferrer">
                  Access Service
                </a>
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServerStatusPage;
